import { NotaEmTransito, Page } from "@/models"
import { Inject } from "inversify-props"
import { NotasEmTransitoServiceAdapter } from "./adapter"
import { AxiosRequestConfig } from "axios"
import { imprimirNotaEmTransito } from "@/shareds/venda-shareds"

export class NotasEmTransitoUseCase {
	@Inject('NotasEmTransitoServiceAdapter')
	private notasEmTransitoServiceAdapter!: NotasEmTransitoServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<NotaEmTransito>> =>
		await this.notasEmTransitoServiceAdapter.find(params, config)

	receberNotaEmTransito = async (nota: NotaEmTransito): Promise<NotaEmTransito> =>
		await this.notasEmTransitoServiceAdapter.receberNotaEmTransito(nota)

	imprimirNotaEmTransito = async (notaEmTransito: NotaEmTransito): Promise<any> =>
		imprimirNotaEmTransito(notaEmTransito)

	remover = async (params: any): Promise<void> =>
		await this.notasEmTransitoServiceAdapter.remover(params)
}