import request from '@/common/request'
import {
	Page,
	Produto,
	Atributo,
	Marca,
	Tag,
	FormProduto,
	FiltroDeBuscaDeProdutos,
	SituacaoDoProdutoNaLoja,
	Categoria,
	FormProdutoSimples,
	ProdutoProjection,
	Variante,
	AtributosDaVariante,
	Genero,
	ConsultaPrecoDeProduto,
	Estacao,
} from '@/models'
import { Pageable } from '@/models/Pageable'
import { AxiosRequestConfig } from 'axios'

export const find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<FormProdutoSimples>> =>
	request.get(`/produtos`, {
		params,
		...config,
	})

export const findProdutosSeletor = async (params?: any, config?: AxiosRequestConfig): Promise<Page<FormProdutoSimples>> =>
	request.get(`/produtos/seletor`, {
		params,
		...config,
	})

export const valirdarProdutoDesativado = async (produtoId: string): Promise<Produto> =>
	request.get(`/produtos/produto-desativado/${produtoId}`)

export const findOtimizado = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ProdutoProjection>> =>
	request.get(`/produtos/lista`, {
		params,
		...config,
	})

export const updateByFiltro = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Produto>> =>
	request.patch(`/produtos/filtros`, {
		params,
		...config,
	})

export const get = async (id: string): Promise<Produto> =>
	request.get(`/produtos/${id}`)

export const update = async (produto: Produto): Promise<Produto> =>
	request.put(`/produtos/${produto.id}`, produto)

export const create = async (produto: Produto): Promise<Produto> =>
	request.post(`/produtos`, produto)

export const patch = async (produtos: Partial<FormProduto>[], params?: FiltroDeBuscaDeProdutos & Pageable): Promise<Produto[]> =>
	request.patch(`/produtos`, produtos, {
		params,
	})

export const remove = async (id: string): Promise<void> =>
	request.delete(`/produtos/${id}`)

export const getAtributo = async (id: string): Promise<Atributo> =>
	request.get(`/atributos-do-produto/${id}`)

export const findAtributo = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Atributo>> =>
	request.get(`/atributos-do-produto`, {
		params,
		...config,
	})

export const createAtributo = async (atributo: Atributo): Promise<Atributo> =>
	request.post(`/atributos-do-produto`, atributo)

export const updateAtributo = async (atributo: Atributo): Promise<Atributo> =>
	request.put(`/atributos-do-produto/${atributo.id}`, atributo)

export const findMarca = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Marca>> =>
	request.get(`/marcas`, {
		params,
		...config,
	})
export const createMarca = async (marca: Marca): Promise<Marca> =>
	request.post(`/marcas`, marca)

export const updateMarca = async (marca: Marca): Promise<Marca> =>
	request.put(`/marcas/${marca.id}`, marca)

export const findTag = async (params?: any): Promise<Page<Tag>> =>
	request.get(`/tags`, {
		params: {
			...params,
		},
	})

	
export const findTagColecao = async (params?: any): Promise<string[]> =>
	request.get(`/tags/colecao/nome`, {
		params: {
			...params,
		},
	})
	
export const findCategoria = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Categoria>> =>
	request.get(`/categorias`, {
		params,
		...config,
	})

export const findCategoriaList = async (config?: AxiosRequestConfig): Promise<string[]> =>
	request.get(`/categorias/list`, {
		...config,
	})

export const createCategoria = async (categoria: Categoria): Promise<Categoria> =>
	request.post(`/categorias`, categoria)

export const updateCategoria = async (categoria: Categoria): Promise<Categoria> =>
	request.put(`/categorias/${categoria.id}`, categoria)

export const createTag = async (tag: Tag): Promise<Tag> =>
	request.post(`/tags`, tag)

export const updateTag = async (tag: Tag): Promise<Tag> =>
	request.put(`/tags/${tag.id}`, tag)

export const buscaItensSemPrecoNaTabela = async (
	idTabelaDePreco: string,
	params?: any,
	config?: AxiosRequestConfig,
): Promise<Page<Produto>> =>
	request.get(`/produtos/tabela/${idTabelaDePreco}/itens-sem-preco`, {
		params,
		...config,
	})

export const buscaSituacaoDosProdutos = async (
	idLoja: string,
	params?: any,
	config?: AxiosRequestConfig,
): Promise<Page<SituacaoDoProdutoNaLoja>> =>
	request.get(`/produtos/loja/${idLoja}/infos-adicionais`, {
		params,
		...config,
	})

export const buscarVariantePorAtributos = async (produtoPaiId: string, atributos: AtributosDaVariante[],
): Promise<Variante> =>
	request.post(`/produtos/variante/${produtoPaiId}`,atributos)

export const findGenero = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Genero>> =>
	request.get(`/generos`, {
		params,
		...config,
	})
export const createGenero = async (genero: Genero): Promise<Genero> =>
	request.post(`/generos`, genero)

export const updateGenero = async (genero: Genero): Promise<Genero> =>
	request.put(`/generos/${genero.id}`, genero)

export const findMarcaByLojaId = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Marca>> =>
	request.get('/marcas/lojas', {
		params,
		...config,
	})

export const findMarcaPorUsuario = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Marca>> =>
	request.get('/marcas/usuario', {
		params,
		...config,
	})

export const encontrarProdutoPorIdentificador = async (identificador: string): Promise<Produto> =>
	request.get(`/produtos/identificador/${identificador}`)

export const findConsultaPrecoDeProduto = async (produtoId: string, lojaId: string,
): Promise<ConsultaPrecoDeProduto[]> =>
	request.get(`/produtos/${produtoId}/${lojaId}/preco`)

export const findConsultaEstoqueDeProduto = async (produtoId: string, lojaId: string,
): Promise<Record<string, any>[]> =>
	request.get(`/produtos/${produtoId}/${lojaId}/estoque`)

export const findColecao = async (lojaId: string,
): Promise<string[]> =>
	request.get(`/produtos/colecao/${lojaId}`)

export const findEstacao = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Genero>> =>
	request.get(`/estacao`, {
		params,
		...config,
	})
	
export const createEstacao = async (estacao: Estacao): Promise<Estacao> =>
	request.post(`/estacao`, estacao)

export const updateEstacao = async (estacao: Estacao): Promise<Estacao> =>
	request.put(`/estacao/${estacao.id}`, estacao)