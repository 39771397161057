import { Inject } from 'inversify-props'
import { MotivoDaMovimentacaoServiceAdapter } from './adapter'
import { FormMotivoDaMovimentacao, MotivoDaMovimentacao } from '@/models'

export class SaveMotivoDaMovimentacaoUseCase {
	@Inject('MotivoDaMovimentacaoServiceAdapter')
	private motivoDaMovimentacaoService!: MotivoDaMovimentacaoServiceAdapter

	create = async (motivo: FormMotivoDaMovimentacao): Promise<MotivoDaMovimentacao> =>
		await this.motivoDaMovimentacaoService.create(motivo)

	update = async (motivo: FormMotivoDaMovimentacao): Promise<MotivoDaMovimentacao> =>
		await this.motivoDaMovimentacaoService.update(motivo)
}
