import { BandeirasDeCartao } from "@/models"
import { BandeirasDeCartaoServiceAdapter } from "./adapter"
import { Inject } from 'inversify-props'

export class SaveBandeirasDeCartaoUseCase {
	@Inject('BandeirasDeCartaoServiceAdapter')
	private bandeirasDeCartaoAdapter!: BandeirasDeCartaoServiceAdapter

	create = async (bandeira: BandeirasDeCartao) => await this.bandeirasDeCartaoAdapter.create(bandeira)
	update = async (bandeira: BandeirasDeCartao) => await this.bandeirasDeCartaoAdapter.update(bandeira)
}
