import 'reflect-metadata'
import { Inject } from 'inversify-props'
import { SerieENumeroNF } from '@/models'
import { Page } from '@/models'
import { AxiosRequestConfig } from 'axios'
import { FiscalServiceAdapter } from '@/usecases'

export type FindNumerosInutilizaveisParams = {
	lojaId?: string
	serie?: string
	numero?: string
	page?: number
	size?: number
}

export class FindNumerosInutilizaveisUseCase {
	@Inject('FiscalServiceAdapter')
	private service!: FiscalServiceAdapter

	find = async (params?: FindNumerosInutilizaveisParams, config?: AxiosRequestConfig): Promise<Page<SerieENumeroNF>> =>
		await this.service.findNumerosInutilizaveis(params, config)
}
