import { Inject } from 'inversify-props'
import { RegraDeAplicacaoServiceAdapter } from '@/usecases'
import { RegraDeAplicacao } from '@/models'

export class SaveRegraDeAplicacaoUseCase {
	@Inject('RegraDeAplicacaoServiceAdapter')
	private regraService!: RegraDeAplicacaoServiceAdapter

	update = async (regra: RegraDeAplicacao) =>
		await this.regraService.update(regra)

	copiar = async (idRegraDestino: string, idRegraOrigem: string) =>
		await this.regraService.copiar(idRegraDestino, idRegraOrigem)
}
