import { Inject } from 'inversify-props'
import { FiscalServiceAdapter } from '@/usecases'
import { SerieInutilizada } from '@/models'
import { AmbienteFiscal } from '@/models/SerieFiscal'

export class InutilizaNumerosUseCase {
	@Inject('FiscalServiceAdapter')
	private service!: FiscalServiceAdapter

	execute = async (seriesFiscais: SerieENumeroNfParam[]): Promise<SerieInutilizada[]> =>
		this.service.inutiliza(seriesFiscais)
}

export type SerieENumeroNfParam = {
	idDaLoja: string
	serie: string
	numero: string
	ambiente: AmbienteFiscal
}
