import { Inject } from 'inversify-props'
import { EstacaoServiceAdapter } from './adapter/EstacaoServiceAdapter'
import { Estacao } from '@/models'

export class SaveEstacaoUseCase {
	@Inject('EstacaoServiceAdapter')
	private estacaoService!: EstacaoServiceAdapter

	create = async (estacao: Estacao) => await this.estacaoService.create(estacao)

	update = async (estacao: Estacao) => await this.estacaoService.update(estacao)
}
