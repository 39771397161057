import { Inject } from 'inversify-props'
import { RegraPorEstadoServiceAdapter } from '@/usecases'
import { Page, RegraPorEstado } from '@/models'
import { AxiosRequestConfig } from 'axios'

export class FindRegraPorEstadoUseCase {
	@Inject('RegraPorEstadoServiceAdapter')
	private regraService!: RegraPorEstadoServiceAdapter

	find = async (idRegraAplicacao: string, ufOrigem: string, params?: any, config?: AxiosRequestConfig): Promise<Page<RegraPorEstado>> =>
		await this.regraService.find(idRegraAplicacao, ufOrigem, params, config)
}
