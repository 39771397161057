import { CapaDaNotaServiceAdapter } from './adapter/CapaDaNotaServiceAdapter';
import { AxiosRequestConfig } from 'axios';
import { Inject } from 'inversify-props';
import { Page, CapaDaNota, FiltroDeCapaDaNota } from '@/models';
export class FindCapaDaNotaUseCase {
	@Inject('CapaDaNotaServiceAdapter')
	private capaDaNotaServiceAdapter!: CapaDaNotaServiceAdapter

	find = async (params?: FiltroDeCapaDaNota, config?: AxiosRequestConfig): Promise<Page<CapaDaNota>> =>
		await this.capaDaNotaServiceAdapter.find(params, config)

	downloadXml = async (params: FiltroDeCapaDaNota, config?: AxiosRequestConfig): Promise<any> =>
		await this.capaDaNotaServiceAdapter.downloadXml(params, config)
}
