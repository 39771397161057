import request from "@/common/request"
import { FormMotivoDaMovimentacao, MotivoDaMovimentacao, Page } from "@/models"
import { AxiosRequestConfig } from "axios"

const PATH = '/motivos-de-movimentacao'

export const create = async (data: FormMotivoDaMovimentacao): Promise<MotivoDaMovimentacao> =>
	await request.post(`${PATH}`, data)

export const update = async (data: FormMotivoDaMovimentacao): Promise<MotivoDaMovimentacao> =>
	await request.put(`${PATH}/${data.id}`, data)

export const get = async (id: string): Promise<MotivoDaMovimentacao> =>
	await request.get(`${PATH}/${id}`)

export const find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<MotivoDaMovimentacao>> =>
	await request.get(`${PATH}`, {params, ...config })

export const remove = async (id: string): Promise<void> =>
	request.delete(`${PATH}/${id}`)
