import { Inject } from "inversify-props";
import { OrigemServiceAdapter } from "../adapter/OrigemServiceAdapter";
import { Origem, Page } from "@/models";

export class FindOrigemUseCase {
	@Inject("OrigemServiceAdapter")
	private origemService!: OrigemServiceAdapter;

	findById = async (id: string): Promise<Origem> =>
		await this.origemService.get(id);

	find = async (params?: any): Promise<Page<Origem>> =>
		await this.origemService.find(params);
}
