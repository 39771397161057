import { Inject } from "inversify-props";
import { PreVendaServiceAdapter } from "../pre-venda";
import { Page } from "@/models";
import { PreVenda, PreVendaListagem } from "@/models/pre-venda/PreVenda";
import { AxiosRequestConfig } from "axios";

export class PreVendaUseCase {
	@Inject('PreVendaServiceAdapter')
	private preVendaService!: PreVendaServiceAdapter

	findPreVendaByFiltro = async (params: any, config?: AxiosRequestConfig): Promise<PreVenda> =>
		await this.preVendaService.findPreVendaByFiltro(params, config)
	
	findPreVendas = async (params: any, config?: AxiosRequestConfig): Promise<Page<PreVenda>> =>
		await this.preVendaService.findPreVendas(params, config)

	remover = async (idPreVenda: string): Promise<void> =>
		await this.preVendaService.remover(idPreVenda) 

	salvar = async (preVenda: PreVenda): Promise<PreVenda> =>
		await this.preVendaService.salvar(preVenda)

	updateSituacao = async (preVenda: PreVenda): Promise<void> =>
		await this.preVendaService.updateSituacao(preVenda)

	findPreVendaListagem = async (params: any, config?: AxiosRequestConfig): Promise<PreVendaListagem[]> =>
		await this.preVendaService.findPreVendaListagem(params, config)
}