import { AxiosRequestConfig } from 'axios';
import { Inject } from 'inversify-props'
import { RelatorioServiceAdapter } from "./adapter";
import { ViewPerfil } from '@/models/views/ViewRelatorio';

export class SaveRelatorioUseCase {
	@Inject('RelatorioServiceAdapter')
	private relatorioService!: RelatorioServiceAdapter

	salvarViewPerfis = async (viewPerfis: ViewPerfil[], config?: AxiosRequestConfig): Promise<void> =>
		await this.relatorioService.salvarViewPerfis(viewPerfis, config)
}