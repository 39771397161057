import { Inject } from "inversify-props"
import { EstoqueServiceAdapter } from "../deposito"
import { AxiosRequestConfig } from "axios"
import { ListagemItensRomaneio } from "@/models/Romaneio"

export class ItemRomaneioEstoqueUseCase {

	@Inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter



	async findItensRomaneio(params?: any, config?: AxiosRequestConfig): Promise<ListagemItensRomaneio> {
		return this.estoqueService.findItensRomaneio(params, config)
	} 

}