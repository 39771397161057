import { ConfiguracaoDeCupom, CupomDeDesconto, Page } from '@/models'
import { AxiosRequestConfig } from 'axios'
import { Inject } from 'inversify-props'
import { ConfiguracaoDeCupomServiceAdapter } from './adapter/ConfiguracaoDeCupomServiceAdapter'

export class FindConfiguracaoDeCupomUseCase {
	@Inject('ConfiguracaoDeCupomServiceAdapter')
	private configuracaoDeCupomServiceAdapter!: ConfiguracaoDeCupomServiceAdapter

	findById = async (id: string): Promise<ConfiguracaoDeCupom> =>
		await this.configuracaoDeCupomServiceAdapter.get(id)

	findByCodigo = async (codigo: string, params?: any, config?: AxiosRequestConfig): Promise<CupomDeDesconto> =>
		await this.configuracaoDeCupomServiceAdapter.findByCodigo(codigo, params, config)

	findCupons = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ConfiguracaoDeCupom>> =>
		await this.configuracaoDeCupomServiceAdapter.find(params, config)
}
