import { GrupoEconomico } from '@/models/GrupoEconomico'
import { Inject } from 'inversify-props'
import { GrupoEconomicoServiceAdapter } from './adapter'

export class SaveGrupoEconomicoUseCase {
	@Inject('GrupoEconomicoServiceAdapter')
	private grupoEconomicoServiceAdapter!: GrupoEconomicoServiceAdapter

	create = async (grupoEconomico: GrupoEconomico): Promise<GrupoEconomico> =>
		await this.grupoEconomicoServiceAdapter.create(grupoEconomico)

	update = async (grupoEconomico: GrupoEconomico): Promise<GrupoEconomico> =>
		await this.grupoEconomicoServiceAdapter.update(grupoEconomico)
}
