import { ImportacaoDeNota } from '@/models/ImportacaoDeNota'
import { Inject } from 'inversify-props'
import { ImportacaoDeNotaServiceAdapter } from './adapter'
import { FormFornecedor, Fornecedor } from '@/models'

export class ImportarNotaDoFornecedorUseCase {
	@Inject('ImportacaoDeNotaServiceAdapter')
	private importacaoDeNotaServiceAdapter!: ImportacaoDeNotaServiceAdapter

	async execute(
		arquivo: File | null,
		preencherFornecedor: (fornecedor: FormFornecedor) => Promise<Fornecedor | null>,
	): Promise<ImportacaoDeNota> {
		if (!arquivo) throw new Error('Arquivo não informado')

		const importacaoDeNota = await this.importacaoDeNotaServiceAdapter.importar(arquivo)

		if (importacaoDeNota.fornecedor.encontrado) return importacaoDeNota

		const fornecedor = await preencherFornecedor(importacaoDeNota.fornecedor.nota)
		if (!fornecedor) throw new Error('Preencha o fornecedor para continuar')
		
		return {
			...importacaoDeNota,
			fornecedor: {
				...importacaoDeNota.fornecedor,
				encontrado: fornecedor,
			},
		}
	}
}
