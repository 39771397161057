import { Notificacao, Page } from "@/models";
import { AxiosRequestConfig } from "axios";
import { Inject } from "inversify-props";
import { NotificacaoServiceAdapter } from "./adapter";

export class UpdateNotificacaoUseCase {
	@Inject('NotificacaoServiceAdapter')
	private notificacaoService!: NotificacaoServiceAdapter

	updateAll = async (notificacao: Notificacao[]): Promise<Page<Notificacao>> =>
		await this.notificacaoService.updateAll(notificacao)

	update = async (notificacao: Notificacao): Promise<Notificacao> =>
		await this.notificacaoService.update(notificacao)

	findNotificacoes = async (params: any, config?: AxiosRequestConfig): Promise<Page<Notificacao>> =>
		await this.notificacaoService.findNotificacoes(params, config)
}