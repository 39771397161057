import { findRegistros, findPrecoDeCusto, createPrecoDeCusto, patchPrecoDeCusto } from "@/api/registroDeCustos";
import { RegistroDeCustosServiceAdapter } from "@/usecases/custos/adapter";
import { AxiosRequestConfig } from "axios";
import { injectable } from "inversify-props";
import { RegistroDeCustos } from "@/models/RegistroDeCustos";

@injectable()
export class RegistroDeCustosService implements RegistroDeCustosServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) => await findRegistros(params, config)
	findPrecoDeCusto = async (params?: any) => await findPrecoDeCusto(params)
	createPrecoDeCusto = async (registroDeCustos?: RegistroDeCustos) => await createPrecoDeCusto(registroDeCustos)
	patchPrecoDeCusto = async (registroDeCustos?: RegistroDeCustos) => await patchPrecoDeCusto(registroDeCustos)
}