import { Inject } from 'inversify-props'
import { VendaServiceAdapter } from './adapter/VendaServiceAdapter'
import { Venda } from '@/models'
import { AxiosError } from 'axios'

export class PatchVendaUseCase {
	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	async patchCpfNaNota(venda: Partial<Venda>): Promise<void> {
		try {
			await this.vendaService.patchCpfNaNota(venda)
		} catch (error) {
			throw new Error(`Erro ao salvar a venda ${(error as AxiosError).message}`)
		}
	}
}