import { ConfiguracaoDaConta } from '@/models/ConfiguracaoDaConta'
import { Inject } from 'inversify-props'
import { ContaServiceAdapter } from '../cadastro'

export class FindConfiguracaoDaContaUseCase{
	@Inject("ContaServiceAdapter")
	private contaServiceAdapter!: ContaServiceAdapter

	async find(): Promise<ConfiguracaoDaConta>{
		return this.contaServiceAdapter.findConfiguracaoDaConta()
	}
}