import { isAplicacao } from '@/shareds/utils'
import { authGuard } from '@/shareds/router-guards'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import rotasDeProdutos from './rotasDeProdutos'
import rotasDeLojas from './rotasDeLojas'
import rotasDeUsuarios from './rotasDeUsuarios'
import rotasDeClientes from './rotasDeClientes'
import rotasDeVendas from './rotasDeVendas'
import rotasDeRegradeImpostos from './rotasDeRegraDeImpostos'
import rotasNaoAutenticadas from './rotasNaoAutenticadas'
import rotasDePerfis from './rotasDePerfis'
import rotasDeEstoques from './rotasDeEstoques'
import rotasDeTabelaDePrecos from './rotasDeTabelaDePrecos'
import rotasDeLogin from './rotasDeLogin'
import rotasDeConsulta from './rotasDeConsulta'
import rotasDeConfiguracoesfiscais from './rotasDeConfiguracoesFiscais'
import rotasInicio from './rotasDeInicio'
import rotasDeFechamentosDeCaixa from './rotasDeFechamentosDeCaixa'
import rotasDeContas from './rotasDeContas'
import rotasDeDashboards from './rotasDeDashboards'
import rotasDeCupons from './rotasDeCupons'
import rotasDePedidos from './rotasDePedidos'
import rotasDePlugins from './rotasDePlugins'
import rotasDeMarcas from './rotasDeMarcas'
import rotasDeTags from './rotasDeTags'
import rotasDeSituacaoDosProdutos from './rotasDeSituacaoDosProdutos'
import rotasDeAtributos from './rotasDeAtributos'
import rotasDePlanos from './rotasDePlanos'
import rotasDeMotivosDeMovimentacoes from './rotasDeMotivosDeMovimentacoes'
import rotasDeCategoria from './rotasDeCategoria'
import rotasDeEdicaoEmMassa from './rotasDeEdicaoEmMassa'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import rotasDeEtiquetas from './rotasDeEtiquetas'
import rotasDeEntradas from './rotasDeEntradas'
import rotasDeDocumentosFiscais from './rotasDeDocumentosFiscais'
import rotasDeTitulos from './rotasDeTitulos'
import rotasDeRelatorios from './rotasDeRelatorios'
import rotasDeSangria from './rotasDeSangria'
import rotasDeComandas from './rotasDeComandas'
import rotasDeFaq from './rotasDeFaq'
import rotasDeMetas from './rotasDeMetas'
import rotasDeCashback from './rotasDeCashback'
import rotasDeGruposEconomicos from './rotasDeGruposEconomicos'
import rotasDeCustos from './rotasDeCustos'
import rotasDeConsultaDePreco from './rotasDeConsultaDePreco'
import rotasDePreVenda from './rotasDePreVenda'
import rotasDeAnexo from './rotasDeAnexo'
import rotasDeEstacao from './rotasDeEstacao'
import rotasDeMotivosDeCancelamentoFiscal from './rotasDeMotivosDeCancelamentoFiscal'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
	...!isAplicacao
		? rotasNaoAutenticadas
		: [
			...rotasDeLogin,
			...rotasDeConsulta,
			...rotasDeAnexo,
			{
				path: '/',
				component: () => import('@/views/application/Application.vue'),
				beforeEnter: authGuard,
				children: [
					...rotasInicio,
					...rotasDeProdutos,
					...rotasDeUsuarios,
					...rotasDeLojas,
					...rotasDeClientes,
					...rotasDeVendas,
					...rotasDeRegradeImpostos,
					...rotasDePerfis,
					...rotasDeEstoques,
					...rotasDeTabelaDePrecos,
					...rotasDeConfiguracoesfiscais,
					...rotasDeFechamentosDeCaixa,
					...rotasDeContas,
					...rotasDeDashboards,
					...rotasDeCupons,
					...rotasDePedidos,
					...rotasDePlugins,
					...rotasDeMarcas,
					...rotasDeTags,
					...rotasDeSituacaoDosProdutos,
					...rotasDeAtributos,
					...rotasDePlanos,
					...rotasDeMotivosDeMovimentacoes,
					...rotasDeCategoria,
					...rotasDeDocumentosFiscais,
					...rotasDeEdicaoEmMassa,
					...rotasDeEtiquetas,
					...rotasDeTitulos,
					...rotasDeEntradas,
					...rotasDeRelatorios,
					...rotasDeSangria,
					...rotasDeComandas,
					...rotasDeFaq,
					...rotasDeMetas,
					...rotasDeCashback,
					...rotasDeGruposEconomicos,
					...rotasDeCustos,
					...rotasDeConsultaDePreco,
					...rotasDePreVenda,
					...rotasDeEstacao,
					...rotasDeMotivosDeCancelamentoFiscal,
				], 
			},
		],
	{
		path: '*',
		redirect: '/',
	},
]
const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes,
})

router.beforeEach(async (to, from, next) => {
	const token = new URLSearchParams(window.location.search).get('token')
	if (token) {
		try {
			await UserLoginStore.loginComToken(token)
		} catch(error) {
			console.log(error)
			next({ name: 'Entrar' })
			return
		}
	}
	next()
	Vue.nextTick(() => {
		document.title = to.name || 'Almode PDV'
	});
})

export default router
