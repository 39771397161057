import { Inject } from 'inversify-props'
import { RegraDeImpostoServiceAdapter } from '@/usecases'
import { CapaRegraImposto, Page, RegraDeEstado } from '@/models'
import { AxiosRequestConfig } from 'axios'

export class FindRegraDeImpostoUseCase {
	@Inject('RegraDeImpostoServiceAdapter')
	private regraService!: RegraDeImpostoServiceAdapter

	async getRegra(id: string): Promise<CapaRegraImposto> {
		return this.regraService.get(id)
	}

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<CapaRegraImposto>> =>
		await this.regraService.find(params, config)

	getItens = async (regraId: string, params?: any, config?: AxiosRequestConfig): Promise<Page<RegraDeEstado>> =>
		await this.regraService.getItens(regraId, params, config)
}
