import { Inject } from 'inversify-props'
import { EstoqueServiceAdapter } from './adapter'
import { EstoqueProjection, FormDeItemDeEstoque } from '@/models'

export class SaveEstoqueUseCase {
	@Inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter

	create = async (idDeposito: string, estoque: FormDeItemDeEstoque) =>
		await this.estoqueService.create(idDeposito, estoque)

	add = async (idEstoque: string, qtde: number, observacao: string) =>
		await this.estoqueService.add(idEstoque, qtde, observacao)

	retirar = async (idEstoque: string, qtde: number, observacao: string) =>
		await this.estoqueService.retirar(idEstoque, qtde, observacao)

	empenhar = async (idEstoque: string, qtde: number, observacao: string) =>
		await this.estoqueService.empenhar(idEstoque, qtde, observacao)

	reaver = async (idEstoque: string, qtde: number, observacao: string) =>
		await this.estoqueService.reaver(idEstoque, qtde, observacao)

	alterarEstoqueMinimo = async (idEstoque: string, qtdeEstoqueMinimo: EstoqueProjection) => {
		await this.estoqueService.alterarEstoqueMinimo(idEstoque, qtdeEstoqueMinimo)
		
	}
}
