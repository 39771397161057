import 'reflect-metadata'
import { injectable } from 'inversify'
import { DadosDeVendaServiceAdapter } from '@/usecases'
import { DadosDeCustoDeItensVendidosPorCategoria, DadosDeEstoque, DadosDeVendaProcessada, DadosDeVendasPorLoja, DadosDeVendasPorMarca, DadosDeVendasPorVendedor, DadosDemonstracaoLoja, DadosPagamentoPorLoja, DadosVendasExternasEInternas } from '@/models'
import { dadosDeVendas, 
	dadosDeVendasPorVendedor, 
	findDadosDeVendaPorLoja, 
	findDadosDeDemonstracaoPorLoja, 
	findDadosDePagamentoPorLoja, 
	findDadosVendasExternasEInternas,
	findDadosDeVendasOrcamentoPorLoja,
	dadosDeVendasPorVendedorOrcamento,
	findDadosVendasOrcamentoExternasDemonstracao,
	findDadosPagamentosPorLojaOrcamento,
	findDadosDeEstoque,
	dadosDeVendasPorMarca,
	findDadosCustoDeItensVendidosPorCategoria,
} from '@/api/dados-vendas'

@injectable()
export class DadosDeVendasService implements DadosDeVendaServiceAdapter {
	find = async (params: any, config?: any): Promise<DadosDeVendaProcessada[]> => await dadosDeVendas(params, config)

	findDadosDeVendaPorVendedor = async (params: any, config?: any): Promise<DadosDeVendasPorVendedor[]> => await dadosDeVendasPorVendedor(params, config)

	findDadosDeVendaPorMarca = async (params: any, config?: any): Promise<DadosDeVendasPorMarca[]> => await dadosDeVendasPorMarca(params, config)
	
	findDadosDeVendasPorVendedorOrcamento = async (params: any, config?: any): Promise<DadosDeVendasPorVendedor[]> => await dadosDeVendasPorVendedorOrcamento(params, config)

	findDadosDeVendaPorLoja = async (params: any, config?: any): Promise<DadosDeVendasPorLoja[]> => await findDadosDeVendaPorLoja(params, config)

	findDadosDeVendasOrcamentoPorLoja = async (params: any, config?: any): Promise<DadosDeVendasPorLoja[]> => await findDadosDeVendasOrcamentoPorLoja(params, config)

	findDadosDeDemonstracaoPorLoja = async (params: any, config?: any): Promise<DadosDemonstracaoLoja[]> => await findDadosDeDemonstracaoPorLoja(params, config)

	findDadosDePagamentoPorLoja = async (params: any, config?: any): Promise<DadosPagamentoPorLoja[]> => await findDadosDePagamentoPorLoja(params, config)
	
	findDadosPagamentosPorLojaOrcamento = async (params: any, config?: any): Promise<DadosPagamentoPorLoja[]> => await findDadosPagamentosPorLojaOrcamento(params, config)
	
	findDadosVendasExternasEInternas = async (params: any, config?: any): Promise<DadosVendasExternasEInternas[]> => await findDadosVendasExternasEInternas(params, config)

	findDadosVendasOrcamentoExternasDemonstracao = async (params: any, config?: any): Promise<DadosVendasExternasEInternas[]> => await findDadosVendasOrcamentoExternasDemonstracao(params, config)
	
	findDadosDeEstoque = async (params: any, config?: any): Promise<DadosDeEstoque[]> => await findDadosDeEstoque(params, config)

	findDadosCustoDeItensVendidosPorCategoria = async (params: any, config?: any): Promise<DadosDeCustoDeItensVendidosPorCategoria[]> => await findDadosCustoDeItensVendidosPorCategoria(params, config)
}
