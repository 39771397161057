import { Inject } from 'inversify-props'
import { EstoqueServiceAdapter } from '.'
import {
	Deposito,
	ProdutoComposto,
	ItemDoProdutoComposto,
	ProdutoComVariantes,
} from '@/models'
import { FormDeConversaoDeEstoque } from '@/models/estoque/ConversaoDeEstoque'

export class ConversaoDeEstoqueUseCase {
	@Inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter

	montar = async (
		deposito: Deposito,
		conversaoForm: FormDeConversaoDeEstoque,
	) => {
		if (!conversaoForm.produto) return
		if (!conversaoForm.quantidade) return
		if (!deposito.id) return

		conversaoForm.produto.itens.forEach(item =>
			verificaQtdeEmEstoque(item, deposito, conversaoForm.quantidade as number),
		)
		return await this.estoqueService.montar(deposito.id, conversaoForm)
	}

	desmontar = async (
		deposito: Deposito,
		conversaoForm: FormDeConversaoDeEstoque,
	) => {
		if (!conversaoForm.produto) return
		if (!conversaoForm.quantidade) return
		if (!deposito.id) return

		verificaQtdeProdutoComposto(
			conversaoForm.produto,
			deposito,
			conversaoForm.quantidade,
		)
		return await this.estoqueService.desmontar(deposito.id, conversaoForm)
	}

	qtdeMaximaMontagem = (
		produto: ProdutoComposto,
		deposito: Deposito,
	): number => {
		const qtdesEmEstoque: number[] = []
		produto.itens.forEach(item => {
			const itemDoProdutoComposto = item.produto as ProdutoComVariantes
			let estoque = deposito.itens.find(
				({ produto }) => {
					return produto.id === item.produto.id
				},
			)
			
			if (!estoque && itemDoProdutoComposto.variantes) {
				estoque = deposito.itens.find(
					({ produto }) => itemDoProdutoComposto.variantes.some(
						variante => variante.id === produto.id,
					),
				)
			}

			if (!estoque) {
				qtdesEmEstoque.push(0)
				return
			}
			const qtdDisponivel = (estoque.qtdeEstoque || 0) - estoque.qtdeEmpenhada
			if (qtdDisponivel == 0 && qtdDisponivel < item.quantidade) {
				qtdesEmEstoque.push(0)
			} else {
				const quantosCompostos = qtdDisponivel / item.quantidade
				qtdesEmEstoque.push(quantosCompostos)
			}
		})

		return Math.min(...qtdesEmEstoque)
	}
}

function verificaQtdeEmEstoque(
	itemProdutoComposto: ItemDoProdutoComposto,
	deposito: Deposito,
	qtde: number,
) {
	const qtdeNecessaria = itemProdutoComposto.quantidade * qtde
	const varianteItemDoProdutoComposto = itemProdutoComposto.produto as ProdutoComVariantes

	let estoqueProduto = deposito.itens.find(
		({ produto }) => produto.id === itemProdutoComposto.produto.id,
	)

	if (!estoqueProduto && varianteItemDoProdutoComposto.variantes)  {
		estoqueProduto = deposito.itens.find(
			({ produto }) => varianteItemDoProdutoComposto.variantes.some(
				variante => variante.id === produto.id,
			),
		)
	}

	if (!estoqueProduto) throw new Error('Estoque de peças insuficiente!')

	const qtdeDisponivel =
		estoqueProduto.qtdeEstoque - estoqueProduto.qtdeEmpenhada

	if (qtdeDisponivel < qtdeNecessaria)
		throw new Error('Estoque de peças insuficiente!')
}

function verificaQtdeProdutoComposto(
	produto: ProdutoComposto,
	deposito: Deposito,
	qtde: number,
) {
	const estoqueProduto = deposito.itens.find(
		item => item.produto.id === produto.id,
	)

	if (!estoqueProduto) throw new Error('Estoque de produto insuficiente!')

	const qtdeDisponivel =
		estoqueProduto.qtdeEstoque - estoqueProduto.qtdeEmpenhada

	if (qtdeDisponivel < qtde) throw new Error('Estoque de produto insuficiente!')
}
