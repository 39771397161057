import { ConfiguracaoDaConta, ConfiguracaoDaContaForm } from '@/models/ConfiguracaoDaConta';
import { Inject } from 'inversify-props';
import { ContaServiceAdapter } from '../cadastro';

export class EditarConfiguracaoDaContaUseCase{
	@Inject("ContaServiceAdapter")
	private contaServiceAdapter!: ContaServiceAdapter

	async save(configuracaDaConta: ConfiguracaoDaContaForm): Promise<ConfiguracaoDaConta>{
		return this.contaServiceAdapter.putConfiguracaoDaConta(configuracaDaConta)
	}
}