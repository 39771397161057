import { Inject } from 'inversify-props';
import { AtributoServiceAdapter } from './adapter'
import { Atributo, Page } from '@/models';
import { AxiosRequestConfig } from 'axios';


export class FindAtributoUseCase {
	@Inject("AtributoServiceAdapter")
	private atributoAdapter!: AtributoServiceAdapter

	findAll = async (params?: any): Promise<Atributo[]> =>
		(await this.atributoAdapter.find(params)).content
	
	findById = async (id: string): Promise<Atributo> =>
		await this.atributoAdapter.get(id)

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Atributo>> =>
		this.atributoAdapter.find(params, config)
}