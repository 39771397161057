import { TurnoDeVendaServiceAdapter } from '.'
import { Inject } from 'inversify-props'
import { MovimentacaoDeCaixa } from '@/models'

export class MovimentarCaixaUseCase {
	@Inject('TurnoDeVendaServiceAdapter')
	private turnoService!: TurnoDeVendaServiceAdapter

	async execute(
		turnoId: string,
		movimentacao: MovimentacaoDeCaixa,
	): Promise<MovimentacaoDeCaixa> {
		return await this.turnoService.createMovimentacao(turnoId, movimentacao)
	}

	async update(
		turnoId: string,
		movimentacaoId: string,
		movimentacao: MovimentacaoDeCaixa,
	): Promise<MovimentacaoDeCaixa>{
		return await this.turnoService.updateMovimentacao(turnoId, movimentacaoId, movimentacao)
	}
}
