import { AxiosRequestConfig } from 'axios';
import { Inject } from 'inversify-props'
import { RelatorioServiceAdapter } from "./adapter";
import { Page, ResumoDaVenda, TotalizadoresDeVenda } from '@/models'
import { DocumentoFiscal, TotalizadoresDeDocumentosFiscais } from '@/models/fiscal/DocumentoFiscal';
import { CurvaAbc, TotalizadoresDeCurvaAbc } from '@/models/CurvaAbc';
import { Relatorio, ViewPerfil, ViewRelatorio } from '@/models/views/ViewRelatorio';

export class FindRelatorioUseCase {
	@Inject('RelatorioServiceAdapter')
	private relatorioService!: RelatorioServiceAdapter

	listVendas = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ResumoDaVenda>> =>
		await this.relatorioService.listVendas(params, config)

	getTotalDeVendas = async (params?: any, config?: AxiosRequestConfig): Promise<TotalizadoresDeVenda> =>
		await this.relatorioService.getTotalDeVendas(params, config)

	listDocumentos = async (params?: any, config?: AxiosRequestConfig): Promise<Page<DocumentoFiscal>> =>
		await this.relatorioService.listDocumentosFiscais(params, config)

	getTotalDeDocumentosFiscais = async (params?: any, config?: AxiosRequestConfig): Promise<TotalizadoresDeDocumentosFiscais> =>
		await this.relatorioService.getTotalDeDocumentosFiscais(params, config)

	listCurvaAbc = async (params?: any, config?: AxiosRequestConfig): Promise<Page<CurvaAbc>> =>
		await this.relatorioService.listCurvaAbc(params, config)

	getTotaisDeCurvaAbc = async (params?: any, config?: AxiosRequestConfig): Promise<TotalizadoresDeCurvaAbc> =>
		await this.relatorioService.getTotaisDeCurvaAbc(params, config)

	listarViews = async (config?: AxiosRequestConfig): Promise<ViewRelatorio[]> => 
		await this.relatorioService.listarViews(config)

	efetuarConsultaRelatorio = async (idView: string, params?: any, config?: AxiosRequestConfig): Promise<object[]> =>
		await this.relatorioService.efetuarConsultaRelatorio(idView, params, config)

	gerarExcel = async (idView: string, params?: any, config?: AxiosRequestConfig): Promise<object[]> =>
		await this.relatorioService.gerarExcel(idView, params, config)

	listarViewPorPerfil = async (perfilId: string, config?: AxiosRequestConfig): Promise<ViewPerfil[]> =>
		await this.relatorioService.listarViewPorPerfil(perfilId, config)

	listarViewsPorPerfil = async (idPerfil: string, config?: AxiosRequestConfig): Promise<ViewRelatorio[]> => 
		await this.relatorioService.listarViewsPorPerfil(idPerfil, config)

	listarRelatorios = async (relatorioId: string, params?: any, config?: AxiosRequestConfig): Promise<Page<Relatorio>> =>
		await this.relatorioService.listarRelatorios(relatorioId, params, config)

	listarOpcoesDeFiltro = async (filtroId: string): Promise<string[]> =>
		await this.relatorioService.listarOpcoesDeFiltro(filtroId)
}