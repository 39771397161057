import { Inject } from 'inversify-props'
import { TituloServiceAdapter } from './adapter'

export class DeleteTituloUseCase {
	@Inject('TituloServiceAdapter')
	private tituloServiceAdapter!: TituloServiceAdapter

	execute = async (id: string): Promise<void> =>
		await this.tituloServiceAdapter.delete(id)
}
