import { MotivosCancelamentoFiscal, Page } from "@/models"
import { AxiosRequestConfig } from "axios"
import { MotivoDeCancelamentoFiscalServiceAdapter } from "./adapter"
import { Inject } from "inversify-props"

export class FindMotivoDeCancelamentoFiscalUseCase {
	@Inject('MotivoDeCancelamentoFiscalServiceAdapter')
	private motivoDeCancelamentoFiscalAdapter!: MotivoDeCancelamentoFiscalServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<MotivosCancelamentoFiscal>> =>
		this.motivoDeCancelamentoFiscalAdapter.find(params, config)
}