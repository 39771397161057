import { Inject } from 'inversify-props'
import { RegraDeAplicacaoServiceAdapter } from '@/usecases'
import { Page, RegraDeAplicacao } from '@/models'
import { AxiosRequestConfig } from 'axios'

export class FindRegraDeAplicacaoUseCase {
	@Inject('RegraDeAplicacaoServiceAdapter')
	private regraService!: RegraDeAplicacaoServiceAdapter

	async getRegra(id: string): Promise<RegraDeAplicacao> {
		return this.regraService.get(id)
	}

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<RegraDeAplicacao>> =>
		await this.regraService.find(params, config)

	findByRegraImposto = async (idRegraImposto: string, params?: any, config?: AxiosRequestConfig): Promise<Page<RegraDeAplicacao>> =>
		await this.regraService.findByRegraImposto(idRegraImposto, params, config)
	
	update = async (regra: RegraDeAplicacao): Promise<RegraDeAplicacao> =>
		await this.regraService.update(regra)

	async getEstadosOrigemPorRegraAplicacao(id: string): Promise<string[]> {
		return this.regraService.getEstadosOrigemPorRegraAplicacao(id)
	}
}
