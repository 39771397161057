import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeClientes: RouteConfig[] = [
	{
		path: 'clientes',
		name: 'Clientes',
		components: {
			default: () =>
				import('@/views/application/clientes/TelaDeClientes.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'clientes' as TipoDeRecurso,
		},
	},
	{
		path: 'tela-de-aniversariantes',
		name: 'Tela de aniversariantes',
		components: {
			default: () =>
				import('@/views/application/clientes/TelaDeAniversariantes.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'aniversariantes' as TipoDeRecurso,
		},
	},
]

export default rotasDeClientes