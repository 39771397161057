import { Inject } from 'inversify-props'
import { CodigoEDescricao } from '@/models/shareds/CodigoEDescricao'
import { Page } from '@/models'
import { PISServiceAdapter } from '../adapter/PISServiceAdapter'

export class FindPISUseCase {
	@Inject('PISServiceAdapter')
	private pisService!: PISServiceAdapter

	findById = async (id: string): Promise<CodigoEDescricao> =>
		await this.pisService.get(id)

	find = async (params?: any): Promise<Page<CodigoEDescricao>> =>
		await this.pisService.find(params)
}
