import { Inject } from "inversify-props";
import { TicketTiflux } from "@/models";
import { TifluxServiceAdapter } from "./adapter/TifluxServiceAdapter";

export class FindTicketTifluxUseCase {
	@Inject('TifluxServiceAdapter')
	private tifluxService!: TifluxServiceAdapter

	async fetchTicketInfo(numeroTicket: string): Promise<TicketTiflux> {
		return await this.tifluxService.fetchTicketInfo(numeroTicket)
	}
}