import { ItemDaTabelaDePreco, Page, TabelaDePreco } from '@/models'
import { AxiosRequestConfig } from 'axios'
import {Inject} from "inversify-props"
import { TabelaDePrecoServiceAdapter } from '@/usecases'

export class FindTabelaDePrecoUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<TabelaDePreco>> =>
		await this.tabelaService.find(params, config)

	async get(id: string): Promise<TabelaDePreco> {
		return this.tabelaService.get(id)
	}

	buscaItensPorTabelaDePreco = async (id: string, params?: any, config?: AxiosRequestConfig): Promise<Page<ItemDaTabelaDePreco>> =>
		await this.tabelaService.buscaItensPorTabelaDePreco(id, params, config)
}
