import { Inject } from "inversify-props"
import { TabelaDePrecoServiceAdapter } from '@/usecases'

export class DeleteAllItensUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	async execute(idTabela: string): Promise<void>{
		return this.tabelaService.removeAllItens(idTabela)
	}
}