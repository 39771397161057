import { Inject } from 'inversify-props'
import { CodigoEDescricao } from '@/models/shareds/CodigoEDescricao'
import { Page } from '@/models'
import { COFINSServiceAdapter } from '../adapter/COFINSServiceAdapter'

export class FindCOFINSUseCase {
	@Inject('COFINSServiceAdapter')
	private cofinsService!: COFINSServiceAdapter

	findById = async (id: string): Promise<CodigoEDescricao> =>
		await this.cofinsService.get(id)

	find = async (params?: any): Promise<Page<CodigoEDescricao>> =>
		await this.cofinsService.find(params)

}
