import { RelatorioServiceAdapter } from "@/usecases";
import { getTotalDeVendas, listRelatorio } from '@/api/venda'
import { listDocumentosFiscais, getTotalDeDocumentosFiscais } from "@/api/notasFiscais";
import { FiltroDeRelatorioDeVendas } from "@/models/relatorios/RelatorioDeVendas";
import { FiltroDeDocumentosFiscais } from "@/models/relatorios/FiltroDeDocumentosFiscais";
import { FiltroDeCurvaAbc } from "@/models/CurvaAbc";
import { getTotaisDeCurvaAbc, listRelatorioCurvaAbc } from "@/api/curva-abc";
import {
	efetuarConsultaRelatorio,
	listarViews,
	listarViewPorPerfil,
	salvarViewPerfis,
	listarViewsPorPerfil,
	listarRelatorios,
	listarOpcoesDeFiltro,
	gerarExcel,
} from "@/api/view-relatorio";
import { ViewPerfil } from "@/models/views/ViewRelatorio";


export class RelatorioService implements RelatorioServiceAdapter {
	listVendas = async (params?: FiltroDeRelatorioDeVendas) => await listRelatorio(params)
	getTotalDeVendas = async (params?: FiltroDeRelatorioDeVendas) => await getTotalDeVendas(params)
	listDocumentosFiscais = async (params?: FiltroDeDocumentosFiscais) => await listDocumentosFiscais(params)
	getTotalDeDocumentosFiscais = async (params?: FiltroDeDocumentosFiscais) => await getTotalDeDocumentosFiscais(params)
	listCurvaAbc = async (params?: FiltroDeCurvaAbc) => await listRelatorioCurvaAbc(params)
	getTotaisDeCurvaAbc = async (params?: FiltroDeCurvaAbc) => await getTotaisDeCurvaAbc(params)
	listarViews = async () => await listarViews()
	efetuarConsultaRelatorio = async (idView: string, params?: any) => await efetuarConsultaRelatorio(idView, params)
	gerarExcel = async (idView: string, params?: any) => await gerarExcel(idView, params)
	listarViewPorPerfil = async (idPerfil: string) => await listarViewPorPerfil(idPerfil)
	salvarViewPerfis = async (viewPerfis: ViewPerfil[]) => await salvarViewPerfis(viewPerfis)
	listarViewsPorPerfil = async (idPerfil: string) => await listarViewsPorPerfil(idPerfil)
	listarRelatorios = async (relatorioId: string, params?: any) => await listarRelatorios(relatorioId, params)
	listarOpcoesDeFiltro = async (filtroId: string) => await listarOpcoesDeFiltro(filtroId)
}