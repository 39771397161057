import { Adquirente, Page } from "@/models"
import { AdquirenteServiceAdapter } from "./adapter/AdquirenteServiceAdapter"
import { AxiosRequestConfig } from "axios"
import { Inject } from "inversify-props"

export class FindAdquirenteUseCase {
	@Inject('AdquirenteServiceAdapter')
	private adquirenteServiceAdapter!: AdquirenteServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Adquirente>> =>
		this.adquirenteServiceAdapter.find(params, config)
}