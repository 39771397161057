import { Inject } from 'inversify-props'
import { MarcaServiceAdapter } from './adapter/MarcaServiceAdapter'
import { Marca, Page } from '@/models'
import { AxiosRequestConfig } from 'axios'

export class FindMarcaUseCase {
	@Inject('MarcaServiceAdapter')
	private marcaAdapter!: MarcaServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Marca>> =>
		this.marcaAdapter.find(params, config)

	findMarcaPorUsuario = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Marca>> =>
		this.marcaAdapter.findMarcaPorUsuario(params, config)
	
	findMarcasDaLoja = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Marca>> =>
		this.marcaAdapter.findMarcaByLojaId(params, config)

}
