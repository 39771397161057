import { Inject } from 'inversify-props'
import { AxiosRequestConfig } from 'axios'
import { DadosDeCustoDeItensVendidosPorCategoria, DadosDeEstoque, DadosDeVendaProcessada, DadosDeVendasPorLoja, DadosDeVendasPorMarca, DadosDeVendasPorVendedor, DadosDemonstracaoLoja, DadosPagamentoPorLoja, DadosVendasExternasEInternas } from '@/models'
import { DadosDeVendaServiceAdapter } from '@/usecases/data/adapters/DadosDeVendaServiceAdapter'

export class FindDadosDeVendasUsecase {
	@Inject('DadosDeVendasServiceAdapter')
	private dadosDeVendasService!: DadosDeVendaServiceAdapter

	find = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosDeVendaProcessada[]> =>
		await this.dadosDeVendasService.find(params, config)

	findDadosDeVendaPorVendedor = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosDeVendasPorVendedor[]> =>
		await this.dadosDeVendasService.findDadosDeVendaPorVendedor(params, config)
	
	findDadosDeVendaPorMarca = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosDeVendasPorMarca[]> =>
		await this.dadosDeVendasService.findDadosDeVendaPorMarca(params, config)

	findDadosDeVendasPorVendedorOrcamento = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosDeVendasPorVendedor[]> =>
		await this.dadosDeVendasService.findDadosDeVendasPorVendedorOrcamento(params, config)

	findDadosDeVendaPorLoja = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosDeVendasPorLoja[]> =>
		await this.dadosDeVendasService.findDadosDeVendaPorLoja(params, config)
		

	findDadosDeVendasOrcamentoPorLoja = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosDeVendasPorLoja[]> =>
		await this.dadosDeVendasService.findDadosDeVendasOrcamentoPorLoja(params, config)
		
	findDadosDeDemonstracaoPorLoja = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosDemonstracaoLoja[]> =>
		await this.dadosDeVendasService.findDadosDeDemonstracaoPorLoja(params, config)

	findDadosDePagamentoPorLoja = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosPagamentoPorLoja[]> =>
		await this.dadosDeVendasService.findDadosDePagamentoPorLoja(params, config)

	findDadosDePagamentoPorLojaOrcamento  = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosPagamentoPorLoja[]> =>
		await this.dadosDeVendasService.findDadosPagamentosPorLojaOrcamento(params, config)
		
	findDadosVendasExternasEInternas = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosVendasExternasEInternas[]> =>
		await this.dadosDeVendasService.findDadosVendasExternasEInternas(params, config)

		
	findDadosVendasOrcamentoExternasDemonstracao = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosVendasExternasEInternas[]> =>
		await this.dadosDeVendasService.findDadosVendasOrcamentoExternasDemonstracao(params, config)

	findDadosDeEstoque = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosDeEstoque[]> =>
		await this.dadosDeVendasService.findDadosDeEstoque(params, config)
		
	findDadosCustoDeItensVendidosPorCategoria  = async (params: ParametrosFindDadosDeVenda, config?: AxiosRequestConfig): Promise<DadosDeCustoDeItensVendidosPorCategoria[]> =>
		await this.dadosDeVendasService.findDadosCustoDeItensVendidosPorCategoria(params, config)
}

export type ParametrosFindDadosDeVenda = {
	lojas?: string[]
	pdvs?: string[]
	dataInicial: string
	dataFinal?: string
}
