import { Inject } from 'inversify-props'
import { Titulo, SituacaoDoTitulo, TituloComParcelamento } from '@/models'
import { TituloServiceAdapter } from '../adapter/TituloServiceAdapter';

export class SaveContasAPagarUseCase {
	@Inject('TituloServiceAdapter')
	private tituloServiceAdapter!: TituloServiceAdapter

	async execute(params: TituloComParcelamento): Promise<Titulo> {
		
		return !params.titulo.id
			? this.tituloServiceAdapter.create(params)
			: this.tituloServiceAdapter.update(params.titulo)
	}
	
	async updateTitulos(titulosId: string[], situacaoDoTitulo: SituacaoDoTitulo): Promise<void> {
		if (!titulosId) {
			this.tituloServiceAdapter.updateTitulos(titulosId, situacaoDoTitulo)
		}
	}
}
