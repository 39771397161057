import { Inject } from 'inversify-props'
import { EstoqueServiceAdapter } from './adapter'

export class DeleteEstoqueUseCase {
	@Inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter

	delete = async (idDeposito: string, idEstoque: string) =>
		await this.estoqueService.remove(idDeposito, idEstoque)
}
