export * from './COFINSServiceAdapter'
export * from './CSOSNServiceAdapter'
export * from './ServiceICMSAdapter'
export * from './ServiceIPIAdapter'
export * from './PISServiceAdapter'
export * from './RegraDeImpostoServiceAdapter'
export * from './RegraDeAplicacaoServiceAdapter'
export * from './OrigemServiceAdapter'
export * from './CFOPServiceAdapter'
export * from './CESTServiceAdapter'
export * from './NCMServiceAdapter'
export * from './RegraPorEstadoServiceAdapter'
