import { MotivoCancelamento } from "@/models";
import { MotivosDeCancelamentoServiceAdapter } from "./adapter";
import { Inject } from "inversify-props";

export class SaveMotivosDeCancelamentoUseCase {
	@Inject('MotivosDeCancelamentoServiceAdapter')
	private motivosDeCancelamentoAdapter!: MotivosDeCancelamentoServiceAdapter

	create = async (motivo: MotivoCancelamento) => await this.motivosDeCancelamentoAdapter.create(motivo)
	update = async (motivo: MotivoCancelamento) => await this.motivosDeCancelamentoAdapter.update(motivo)
}