import { Inject } from 'inversify-props'
import { PerfilDeUsuarioServiceAdapter } from './adapter'
import { Perfil } from '@/models'

export class UpdatePerfilUseCase {
	@Inject('PerfilDeUsuarioServiceAdapter')
	private perfilService!: PerfilDeUsuarioServiceAdapter

	update = async (perfil: Perfil): Promise<Perfil> =>
		await this.perfilService.update(perfil)
}
