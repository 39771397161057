import { Credenciais, TokenJwt } from "@/models";
import {Inject} from "inversify-props";
import { LoginServiceAdapter } from './adapter/LoginServiceAdapter';

export class LoginUseCase {
	@Inject("LoginServiceAdapter")
	private signInService!: LoginServiceAdapter

	signIn = async (credenciais: Credenciais): Promise<TokenJwt> => 
		await this.signInService.signInWithCredentials(credenciais)
		
		
}		
