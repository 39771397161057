import { Inject } from "inversify-props";
import { ClienteServiceAdapter } from "./adapter";
export class ImportaClientesUseCase {
	@Inject('ClienteServiceAdapter')
	private clienteService!: ClienteServiceAdapter

	async execute(urlDoArquivoCsv: string, arquivoContemCabecalho: boolean ): Promise<any> {
		this.clienteService.importaItens(urlDoArquivoCsv, arquivoContemCabecalho)
	}
	
}