import { Inject } from 'inversify-props';
import { CodigoEDescricao } from '@/models/shareds/CodigoEDescricao';
import { Page } from '@/models';
import { CSOSNServiceAdapter } from '../adapter/CSOSNServiceAdapter';

export class FindCSOSNSUseCase {
	@Inject("CSOSNServiceAdapter")
	private csosnService!: CSOSNServiceAdapter

	findById = async (id: string): Promise<CodigoEDescricao> => 
		await this.csosnService.get(id)
	
	find = async (params?: any): Promise<Page<CodigoEDescricao>> => 
		await this.csosnService.find(params);

}