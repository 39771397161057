import {  Meta } from '@/models/Meta';
import { Inject } from 'inversify-props';
import { MetaServiceAdapter } from './adapter/MetaServiceAdapter';

const ERRO_NA_REQUISICAO = 'Erro ao salvar Meta'

export class SaveMetaUseCase {
	@Inject("MetaServiceAdapter")
	private metaService!: MetaServiceAdapter

	async update(meta: Meta): Promise<Meta> {
		try {
			if (meta.id) {
				const metaResponse = await this.metaService.update(meta)
				if (metaResponse) {
					return metaResponse
				} 
				throw new Error(metaResponse)
			} else {
				const metaResponse = await this.metaService.create(meta)
				if (metaResponse) {
					return metaResponse
				}
				throw new Error(metaResponse)
			}
		} catch (e) {
			throw new Error(`${ERRO_NA_REQUISICAO}: ${e.response.data[0]}`)
		}
	}

}