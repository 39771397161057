import { Inject } from 'inversify-props'
import { Tag } from '@/models'
import { TagServiceAdapter } from './adapter'

export class SaveTagUseCase {
	@Inject('TagServiceAdapter')
	private tagService!: TagServiceAdapter

	create = async (tag: Tag) => await this.tagService.create(tag)
	update = async (tag: Tag) => await this.tagService.update(tag)
}
