import { CapaDashboard, DashGeral, ViewPerfilDashboard } from "@/models/views/CapaDashboard"
import { AxiosRequestConfig } from "axios"
import { Inject } from 'inversify-props'
import { DashboardsServiceAdapter } from "./adapter"

export class FindDashboardsUseCase {
	@Inject('DashboardsServiceAdapter')
	private dashboardsService!: DashboardsServiceAdapter
	
	listarDashs = async (config?: AxiosRequestConfig): Promise<CapaDashboard[]> =>
		await this.dashboardsService.listarDashs(config)

	listarDashPorPerfil = async (perfilId: string, config?: AxiosRequestConfig): Promise<ViewPerfilDashboard[]> =>
		await this.dashboardsService.listarDashPorPerfil(perfilId, config)
	
	findDashGeral = async (params?: any, config?: AxiosRequestConfig): Promise<DashGeral> =>
		await this.dashboardsService.findDashGeral(params, config)
}

	