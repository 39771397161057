import {ImportacaoCsvEstoque } from '@/models';
import {Inject} from "inversify-props";
import { EstoqueServiceAdapter } from './adapter/EstoqueServiceAdapter';

export class ImportaEstoquesUseCase {
	@Inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter

	async execute(idLoja: string, params?: ImportacaoCsvEstoque): Promise<any> {
		this.estoqueService.importaItens(idLoja, params)
	}
}