import { Inject } from "inversify-props";
import { ComandaServiceAdapter } from "./adapter";
import { Comanda, ComandaForm } from "@/models/Comanda";
import { Page } from "@/models";

export class ComandaUseCase {
	@Inject('ComandaServiceAdapter')
	private comandaService!: ComandaServiceAdapter

	async getComandas(busca: string, idLoja: string): Promise<Page<ComandaForm>> {
		return this.comandaService.getComandas(busca, idLoja)
	}

	async findComandasNaoFinalizadasEAbertas(idLoja: string): Promise<ComandaForm[]> {
		return this.comandaService.findComandasNaoFinalizadasEAbertas(idLoja)
	}

	async save(comanda: Comanda): Promise<ComandaForm> {
		return this.comandaService.update(comanda)
	}

}