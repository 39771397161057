import { ModalidadeDeVenda } from "@/models"
import { ModalidadeDeVendaServiceAdapter } from "./adapter"
import { Inject } from 'inversify-props'

export class SaveModalidadeDeVendaUseCase {
	@Inject('ModalidadeDeVendaServiceAdapter')
	private modalidadeDeVendaServiceAdapter!: ModalidadeDeVendaServiceAdapter

	create = async (lojaId: string, modalidade: ModalidadeDeVenda) => await this.modalidadeDeVendaServiceAdapter.create(lojaId, modalidade)
	update = async (modalidade: ModalidadeDeVenda) => await this.modalidadeDeVendaServiceAdapter.update(modalidade)
	remove = async (modalidadeId: string) => await this.modalidadeDeVendaServiceAdapter.remove(modalidadeId)	
}
