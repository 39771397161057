import { injectable } from "inversify-props";
import { editarSituacaoPdv } from '@/api/pdv'
import { PdvServiceAdapter } from "@/usecases";
import { PontoDeVenda } from "@/models";

@injectable()
export class PdvService implements PdvServiceAdapter {

	editarSituacaoPdv = async (pdvId: string, aberto: boolean): Promise<PontoDeVenda> =>
		await editarSituacaoPdv(pdvId, aberto)
}