import { Inject } from 'inversify-props'
import { Page, MotivoDaMovimentacao } from '@/models'
import { AxiosRequestConfig } from 'axios'
import { MotivoDaMovimentacaoServiceAdapter } from './adapter/MotivoDaMovimentacaoServiceAdapter'

export class FindMotivoDaMovimentacaoUseCase {
	@Inject('MotivoDaMovimentacaoServiceAdapter')
	private motivoDaMovimentacaoServiceAdapter!: MotivoDaMovimentacaoServiceAdapter

	find = async (params?: any,  config?: AxiosRequestConfig): Promise<Page<MotivoDaMovimentacao>> =>
		await this.motivoDaMovimentacaoServiceAdapter.find(params, config)

	get = async (id: string): Promise<MotivoDaMovimentacao> =>
		await this.motivoDaMovimentacaoServiceAdapter.get(id)
}