import 'reflect-metadata'
import {
	Module,
	Action,
	Mutation,
	VuexModule,
	getModule,
} from 'vuex-module-decorators'
import store from '@/store/vuex'
import { Plugin, FormPlugin, PluginListeners, EventName, MensagemDoPlugin } from '@/models'
// TODO implementar camadas service,usecases,adapters ....
import * as pluginApi from '@/api/plugin'
import criarPromiseAdiavel from '@/helpers/criarPromiseAdiavel'

@Module({ name: 'plugin', store, dynamic: true, namespaced: true })
class PluginStore extends VuexModule {
	plugins: Plugin[] = []
	listeners: PluginListeners = {} as PluginListeners
	aguardarInicializacao = criarPromiseAdiavel()

	get on() {
		return (type: EventName, listener: PluginListeners[typeof type]) => {
			this.plugins.forEach(plugin => plugin.on(type, listener))
			this.listeners[type] = listener
		}
	}

	get emit() {
		return (type: EventName, params?: any) => {
			this.aguardarInicializacao.then(() => {
				this.plugins.forEach(plugin => plugin.emit(type, params))
			})
		}
	}

	get removeListener() {
		return (type: EventName) => {
			this.plugins.forEach(plugin => plugin.removeListener(type))
		}
	}

	get deixarPluginAssumirControle() {
		return async (type: EventName, params?: any) => {
			const algumPluginAssumiuOControle = await Promise.all(
				// TODO: plugins que não estão prontos podem deixar essa promise pendurada,
				// pensar em uma forma evitar montar plugins se a URL não estiver respondendo.
				this.pluginsProntos.map(plugin => plugin.asyncMessage(type, params)),
			)
			return algumPluginAssumiuOControle.includes(true)
		}
	}

	get pluginsDesmontados(): Plugin[] {
		return this.plugins.filter(plugin => !plugin.montado)
	}

	get pluginsMontados(): Plugin[] {
		return this.plugins.filter(plugin => plugin.montado)
	}

	get pluginsProntos(): Plugin[] {
		return this.plugins.filter(plugin => plugin.pronto)
	}

	get getPlugin() {
		return (id: string) => this.plugins.find(plugin => (plugin.id = id))
	}

	@Action
	async inicializarPlugins() {
		await this.buscarPlugins()
		await this.montarPlugins()
		this.aguardarInicializacao.resolve(true)
	}

	@Action
	async buscarPlugins() {
		try {
			const page = await pluginApi.find()
			const plugins = page.content.map(plugin => new Plugin(plugin))
			this.setPlugins(plugins)
		} catch (error) {
			console.log(error)
		}
	}

	@Action
	async gravarPlugin(plugin: FormPlugin) {
		const pluginSalvo = plugin.id
			? await pluginApi.update(plugin)
			: await pluginApi.create(plugin)
		this.setPlugin(pluginSalvo)
	}

	@Action
	async excluirPlugin(id: string) {
		await pluginApi.remove(id)
		this.deletePlugin(id)
	}

	@Action
	async montarPlugins() {
		this.pluginsDesmontados.map(plugin => {
			plugin.mount()
			Object.assign(plugin.listeners, this.listeners)
		})
	}

	@Action
	async desmontarPlugins() {
		this.pluginsMontados.map(plugin => plugin.unmount())
	}

	@Action
	async pingPlugins() {
		this.pluginsMontados.map(plugin => plugin.ping())
	}

	@Action
	postMessage({ type, params }: MensagemDoPlugin) {
		const results = this.pluginsMontados.map(plugin =>
			plugin.asyncMessage(type, params),
		)
		return results
	}

	@Action
	async asyncMessage({ type, params }: MensagemDoPlugin) {
		const results = await Promise.all(
			this.pluginsMontados.map(plugin => plugin.asyncMessage(type, params)),
		)
		return results
	}

	@Action
	setPlugin(plugin: Plugin) {
		const existe = this.plugins.find(e => e.id === plugin.id)
		if (existe) {
			existe.unmount()
			existe.nome = plugin.nome
			existe.url = plugin.url
			existe.debug = plugin.debug
			existe.configs = { ...plugin.configs }
			existe.mount()
		} else {
			const novoPlugin = new Plugin(plugin)
			this.setPlugins([...this.plugins, novoPlugin])
			novoPlugin.mount()
		}
	}

	@Mutation
	setPlugins(plugins: Plugin[]) {
		this.plugins = plugins
	}

	@Mutation
	deletePlugin(id: string) {
		this.plugins = this.plugins.filter(p => p.id != id)
	}
}

export default getModule(PluginStore)
