import { Inject } from "inversify-props"
import { TabelaDePrecoServiceAdapter } from '@/usecases'

export class DeleteItensUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	async execute(idTabela: string, itens: string[]): Promise<string[]>{
		return this.tabelaService.removeItens(idTabela, itens)
	}
}