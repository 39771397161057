import { MotivosCancelamentoFiscal } from "@/models";
import { MotivoDeCancelamentoFiscalServiceAdapter } from "./adapter";
import { Inject } from "inversify-props";

export class SaveMotivoDeCancelamentoFiscalUseCase {
	@Inject('MotivoDeCancelamentoFiscalServiceAdapter')
	private motivoDeCancelamentoFiscalAdapter!: MotivoDeCancelamentoFiscalServiceAdapter

	create = async (motivo: MotivosCancelamentoFiscal) => await this.motivoDeCancelamentoFiscalAdapter.create(motivo)
	update = async (motivo: MotivosCancelamentoFiscal) => await this.motivoDeCancelamentoFiscalAdapter.update(motivo)
}