import { Page, BandeirasDeCartao } from "@/models"
import { AxiosRequestConfig } from "axios"
import { Inject } from "inversify-props"
import { BandeirasDeCartaoServiceAdapter } from "./adapter"

export class FindBandeirasDeCartaoUseCase {
	@Inject('BandeirasDeCartaoServiceAdapter')
	private bandeirasDeCartaoAdapter!: BandeirasDeCartaoServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<BandeirasDeCartao>> =>
		this.bandeirasDeCartaoAdapter.find(params, config)
}