import 'reflect-metadata'
import { injectable } from 'inversify-props'
import {
	get,
	find,
	findOtimizado,
	update,
	create,
	patch,
	remove,
	buscaItensSemPrecoNaTabela,
	buscaSituacaoDosProdutos,
	buscarVariantePorAtributos,
	valirdarProdutoDesativado,
	encontrarProdutoPorIdentificador,
	findProdutosSeletor,
	findConsultaPrecoDeProduto,
	findConsultaEstoqueDeProduto,
} from '@/api/produtos'
import { ProdutoServiceAdapter } from '@/usecases'
import { AtributosDaVariante, FiltroDeBuscaDeProdutos, FormEdicaoDeProdutos, FormProduto, Page, Produto, ProdutoProjection } from '@/models'
import { Pageable } from '@/models/Pageable'
import { AxiosRequestConfig } from 'axios'
import request from '@/common/request'

const ROTA_BASE = '/produtos'

@injectable()
export class ProdutoService implements ProdutoServiceAdapter {

	valirdarProdutoDesativado = async (produtoId: string) => await valirdarProdutoDesativado(produtoId)
	get = async (id: string) => await get(id)
	find = async (params: any, config?: AxiosRequestConfig) => await find(params, config)
	findProdutosSeletor = async (params: any, config?: AxiosRequestConfig) => await findProdutosSeletor(params, config)
	findOtimizado = async (params: any, config?: AxiosRequestConfig) => await findOtimizado(params, config)
	create = async (produto: Produto) => await create(produto)
	update = async (produto: Produto) => await update(produto)
	changeMultiple = async (produtos: Partial<FormProduto>[], params?: FiltroDeBuscaDeProdutos & Pageable) => await patch(produtos, params)
	remove = async (id: string) => await remove(id)
	buscaItensSemPrecoNaTabela = async (id: string, params?: any, config?: AxiosRequestConfig) => await buscaItensSemPrecoNaTabela(id, params, config)
	buscaSituacaoDosProdutos = async (idLoja: string, params?: any, config?: AxiosRequestConfig) => await buscaSituacaoDosProdutos(idLoja, params, config)
	findConsultaPrecoDeProduto = async (produtoId: string, lojaId: string) => await findConsultaPrecoDeProduto(produtoId, lojaId)
	findConsultaEstoqueDeProduto = async (produtoId: string, lojaId: string) => await findConsultaEstoqueDeProduto(produtoId, lojaId)
	async findByFiltroOtimizado(pageable?: Pageable, config?: AxiosRequestConfig): Promise<Page<ProdutoProjection>> {
		return request.post(`${ROTA_BASE}/lista-filtros`, {
			...config,
			params: {
				...pageable,
				...config?.params,
			},
		})
	}
	async findByFiltro(filtros?: FiltroDeBuscaDeProdutos, pageable?: Pageable, config?: AxiosRequestConfig): Promise<Page<Produto>> {
		return request.post(`${ROTA_BASE}/filtros`, filtros, {
			...config,
			params: {
				...pageable,
				...config?.params,
			},
		})
	}
	async changeByFiltro (produtos: Partial<FormProduto>[], filtro?: FiltroDeBuscaDeProdutos): Promise<Produto[]> {
		return request.patch(`${ROTA_BASE}/filtros`, {
			produtos,
			filtro,
		})
	}
	async changeProdutosByFiltro (editForm: Partial<FormEdicaoDeProdutos>): Promise<Produto[]> {
		return request.patch(`${ROTA_BASE}/massiva`, editForm)
	}
	buscarVariantePorAtributos = async (produtoPaiId: string, atributos: AtributosDaVariante[]) => await buscarVariantePorAtributos(produtoPaiId, atributos)

	encontrarProdutoPorIdentificador = async (identificador: string) => await encontrarProdutoPorIdentificador(identificador)
}
