import { Inject } from 'inversify-props'
import { ClienteServiceAdapter } from './adapter'
import { AniversariantesListagem, Cliente, Page } from '@/models'
import { AxiosRequestConfig } from 'axios'

export class FindClienteUseCase {
	@Inject('ClienteServiceAdapter')
	private clienteServiceAdapter!: ClienteServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cliente>> =>
		await this.clienteServiceAdapter.find(params, config)

	findClienteComCashback = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cliente>> =>
		await this.clienteServiceAdapter.findClienteComCashback(params, config)


	get = async (id: string, params?: any, config?: AxiosRequestConfig): Promise<Cliente> =>
		await this.clienteServiceAdapter.get(id, params, config)

	existeVinculoDeClinteELoja = async (clienteId: string, lojaId: string): Promise<boolean> =>
		await this.clienteServiceAdapter.existeVinculoDeClinteELoja(clienteId, lojaId)
	
	findClientesVinculadosComLoja = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cliente>> =>
		await this.clienteServiceAdapter.findClientesVinculadosComLoja(params, config)
	
	findAniversariantes = async (params?: any, config?: AxiosRequestConfig): Promise<Page<AniversariantesListagem>> =>
		await this.clienteServiceAdapter.findAniversariantes(params, config)

	findQtdCuponsPorCpf = async (params?: any): Promise<number> =>
		await this.clienteServiceAdapter.findQtdCuponsPorCpf(params)
}
