import { Inject } from 'inversify-props'
import { PerfilDeUsuarioServiceAdapter } from './adapter'

export class DeletePerfilUseCase {
	@Inject('PerfilDeUsuarioServiceAdapter')
	private perfilService!: PerfilDeUsuarioServiceAdapter

	delete = async (id: string): Promise<void> =>
		await this.perfilService.remove(id)
}
