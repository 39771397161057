import { Inject } from 'inversify-props'
import { CodigoEDescricao } from '@/models/shareds/CodigoEDescricao'
import { CFOPServiceAdapter } from '..'
import { Page } from '@/models'

export class FindCFOPUseCase {
	@Inject('CFOPServiceAdapter')
	private cfopService!: CFOPServiceAdapter

	findById = async (id: string): Promise<CodigoEDescricao> =>
		await this.cfopService.get(id)

	find = async (params?: any): Promise<Page<CodigoEDescricao>> =>
		await this.cfopService.find(params)

}
