import { Inject } from 'inversify-props'
import { ClienteServiceAdapter } from './adapter'
import { Cliente, FormCliente, FormLoja } from '@/models'

export class SaveClienteUseCase {
	@Inject('ClienteServiceAdapter')
	private clienteService!: ClienteServiceAdapter

	create = async (cliente: FormCliente): Promise<Cliente> =>
		await this.clienteService.create(cliente)

	update = async (cliente: FormCliente): Promise<Cliente> =>
		await this.clienteService.update(cliente)

	vincularLojasComCliente = async (clienteId: string, lojas: FormLoja[]): Promise<void> =>
		await this.clienteService.vincularClienteComLoja(clienteId, lojas)
}
