import { Inject } from "inversify-props"
import { TabelaDePrecoServiceAdapter } from '@/usecases'

export class ExportAllItensUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	exportAllItens(idTabela: string): Promise<any>{
		return this.tabelaService.exportAllItens(idTabela)
	}
}