import { TurnoDeVenda, FormTurnoDeVenda } from '@/models';
import { Inject } from 'inversify-props';
import { TurnoDeVendaServiceAdapter } from '.';

export class AbrirCaixaUseCase {
	@Inject("TurnoDeVendaServiceAdapter")
	private turnoService!: TurnoDeVendaServiceAdapter

	async execute(turnoDeVenda: FormTurnoDeVenda): Promise<TurnoDeVenda> {
		return await this.turnoService.create(turnoDeVenda)
	}

	async podeAbrirCaixa(pdvId: string): Promise<Array<string>> {
		return await this.turnoService.podeAbrirCaixa(pdvId)
	}
}