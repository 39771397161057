import { Page, ModalidadeDeVenda } from "@/models"
import { AxiosRequestConfig } from "axios"
import { Inject } from "inversify-props"
import { ModalidadeDeVendaServiceAdapter } from "./adapter"

export class FindModalidadeDeVendaUseCase {
	@Inject('ModalidadeDeVendaServiceAdapter')
	private ModalidadeDeVendaAdapter!: ModalidadeDeVendaServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ModalidadeDeVenda>> =>
		this.ModalidadeDeVendaAdapter.findAll(params, config)

	find = async (idLoja?: string, config?: AxiosRequestConfig): Promise<Array<ModalidadeDeVenda>> =>
		this.ModalidadeDeVendaAdapter.find(idLoja, config)

	findSequenciaisValidos = async (idLoja?: string): Promise<Array<number>> =>
		this.ModalidadeDeVendaAdapter.findSequenciaisValidos(idLoja)
}