import { ItemDaTabelaDePreco } from '@/models';
import {Inject} from "inversify-props";
import { TabelaDePrecoServiceAdapter } from '@/usecases';

export class UpdateItemUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	async execute(idTabela: string, item: ItemDaTabelaDePreco): Promise<ItemDaTabelaDePreco> {
		return this.tabelaService.alteraItem(idTabela, item)
	}
}
