import { Inject } from 'inversify-props'
import { MarcaServiceAdapter } from './adapter/MarcaServiceAdapter'
import { Marca } from '@/models'

export class SaveMarcaUseCase {
	@Inject('MarcaServiceAdapter')
	private marcaService!: MarcaServiceAdapter

	create = async (marca: Marca) => await this.marcaService.create(marca)

	update = async (marca: Marca) => await this.marcaService.update(marca)
}
