import { Genero, Page } from '@/models';
import { AxiosRequestConfig } from 'axios';
import { Inject } from 'inversify-props'
import { GeneroServiceAdapter } from "./adapter/GeneroServiceAdapter";

export class FindGeneroUseCase {
	@Inject('GeneroServiceAdapter')
	private generoService!: GeneroServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Genero>> =>
		this.generoService.find(params, config)

}