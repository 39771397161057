import { Inject } from 'inversify-props'
import { VendaServiceAdapter } from './adapter'
import { ItemDaVendaComReferencias, Venda } from '@/models'

export class CancelarVendaUseCase {
	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	cancelar = async (id: string, params?: any): Promise<Venda> => {
		return await this.vendaService.cancelar(id, params)
	}

	cancelarItem = async (vendaId: string, params?: any): Promise<ItemDaVendaComReferencias> => {
		return await this.vendaService.cancelarItem(vendaId, params)
	}
}
