import { Notificacao } from "@/models";
import { Inject } from "inversify-props";
import { NotificacaoServiceAdapter } from "./adapter";

export class DispensarNotificacao {
	@Inject('NotificacaoServiceAdapter')
	private notificacaoService!: NotificacaoServiceAdapter

	async executar(notificacao: Notificacao) {
		return await this.notificacaoService.update({
			...notificacao,
			dispensado: true,
		})
	}
}