import { Inject } from 'inversify-props';
import { ProdutoServiceAdapter } from './adapter/ProdutoServiceAdapter';
import { Produto } from '@/models';


export class SaveProdutoUseCase {
	@Inject("ProdutoServiceAdapter")
	private produtoService!: ProdutoServiceAdapter

	create = async (produto: Produto) => 
		await this.produtoService.create(produto)

	update = async (produto: Produto) => 
		await this.produtoService.update(produto)
}