import 'reflect-metadata'
import './router/componentHooks'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/vuex/index'
import vuetify from '@/plugins/vuetify'
import buildDependencyContainer from './app.container'
import 'vue-material-design-icons/styles.css'
import Vuelidate from 'vuelidate'
import AppLoading from '@/components/ui/AppLoading.vue'
import { navigationSubdomain, isVestcasa } from './shareds/utils'
import VueApexCharts from 'vue-apexcharts'
import UserLoginModule from './store/vuex/authentication/UserLoginStore'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import AppStore from './store/vuex/aplicacao/AppStore'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'

class AppBootstrap {
	constructor() {
		this.loadDependencyContainer()
		this.loadVueApp()
	}

	private loadDependencyContainer(): void {
		buildDependencyContainer()
	}

	private async loadVueApp() {
		Vue.component('AppLoading', AppLoading)
		Vue.config.productionTip = false
		Vue.use(Vuelidate)
		Vue.use(VueApexCharts)
		Vue.use(VueQuillEditor)
		

		Vue.component('apexchart', VueApexCharts)
		new Vue({
			vuetify,
			router,
			store,
			
			mounted() {
				document.title = isVestcasa ? 'Vestcasa PDV' : navigationSubdomain
				;(function() {
					if (!isVestcasa) return
					const link: HTMLLinkElement =
						document.querySelector("link[rel*='icon']") ||
						document.createElement('link')
					link.type = 'image/x-icon'
					link.rel = 'shortcut icon'
					link.href =
						'https://cdn.vestcasa.com.br/wp-content/uploads/2020/02/favicon-100x100.ico'
					document.getElementsByTagName('head')[0].appendChild(link)
				})()
				;(function() {
					if (process.env.NODE_ENV === 'development') return
					if (!isVestcasa) return
					const blockContextMenu = function(evt: Event) {
						evt.preventDefault()
					}

					const myElement = document.querySelector('body')
					if (!myElement) return
					myElement.addEventListener('contextmenu', blockContextMenu)
				})()

				if (UserLoginModule.isLoggedIn) {
					AppStore.inicializaSincronizacao()
					PluginModule.inicializarPlugins()
				}
			},
			render: h => h(App),
		}).$mount('#app')
	}
	
}

new AppBootstrap()