import { Estacao, Page } from '@/models';
import { AxiosRequestConfig } from 'axios';
import { Inject } from 'inversify-props'
import { EstacaoServiceAdapter } from "./adapter/EstacaoServiceAdapter";

export class FindEstacaoUseCase {
	@Inject('EstacaoServiceAdapter')
	private estacaoService!: EstacaoServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Estacao>> =>
		this.estacaoService.find(params, config)

}