import { FormCapaTabelaDePreco, LojaTabelaPreco } from '@/models';
import {Inject} from "inversify-props";
import { TabelaDePrecoServiceAdapter } from '@/usecases';

export class UpdateTabelaDePrecoUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	update = (tabela: FormCapaTabelaDePreco) => this.tabelaService.update(tabela)

	updateTabelaPreco = (lojaTabelaId: LojaTabelaPreco[], tabelaId: string ) => this.tabelaService.updateTabelaPreco(lojaTabelaId, tabelaId)
}
