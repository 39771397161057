import request from '@/common/request'
import { Page } from '@/models'
import { Relatorio, ViewPerfil, ViewRelatorio } from '@/models/views/ViewRelatorio'

const basePath = '/views'

export const listarViews = (): Promise<ViewRelatorio[]> =>
	request.get(`${basePath}/listar`)

export const efetuarConsultaRelatorio = (idView: string, params?: any): Promise<object[]> =>
	request.get(`${basePath}/${idView}`, {
		params,
	})

export const gerarExcel = (idView: string, params?: any): Promise<object[]> =>
	request.get(`${basePath}/gerar-excel/${idView}`, {
		params,
	})

export const listarViewPorPerfil = (idPerfil: string): Promise<ViewPerfil[]> =>
	request.get(`${basePath}/perfil/${idPerfil}/listar`)

export const salvarViewPerfis = (viewPerfis: ViewPerfil[]): Promise<void> =>
	request.post(`${basePath}/perfil/salvar`, viewPerfis)

export const listarViewsPorPerfil = (idPerfil: string): Promise<ViewRelatorio[]> =>
	request.get(`${basePath}/listar/${idPerfil}`)

export const listarRelatorios = (relatorioId: string, params?: any): Promise<Page<Relatorio>> =>
	request.get(`${basePath}/relatorios/${relatorioId}`, {
		params,
	})

export const listarOpcoesDeFiltro = (filtroId: string): Promise<string[]> =>
	request.get(`${basePath}/listar/filtro/${filtroId}/opcoes`)