import request from "@/common/request"
import { ModalidadeDeVenda, Page } from "@/models"

export const findAll = async (params?: any): Promise<Page<ModalidadeDeVenda>> =>
	request.get(`/vendas/modalidade-de-venda/${params.lojaId}`, { params })

export const find = async (idLoja?: string): Promise<Array<ModalidadeDeVenda>> =>
	request.get(`/vendas/lista-modalidade-de-venda/${idLoja}`)

export const findSequenciaisValidos = async (idLoja?: string): Promise<Array<number>> =>
	request.get(`/vendas/sequenciais-validos/${idLoja}`)

export const create = async (lojaId: string, modalidade: ModalidadeDeVenda): Promise<ModalidadeDeVenda> =>
	request.post(`/vendas/modalidade-de-venda/${lojaId}/criar-modalidade`, modalidade)

export const update = async (modalidade: ModalidadeDeVenda): Promise<void> =>
	request.patch(`/vendas/modalidade-de-venda/${modalidade.id}`, modalidade)

export const remove = async (modalidadeId: string): Promise<void> =>
	request.post(`/vendas/modalidade-de-venda/remover-modalidade/${modalidadeId}`)